import React from 'react';
import { graphql } from 'gatsby';
import { useLightBox } from '../../hooks/use-light-box';
import { Layout } from '../../components/layout';
import Pin from '/static/pin.svg';
import * as Styles from '../../styles/pages/_artistId.module.scss';
import { BreadCrumb } from '../../components/breadcrumb';
import { BackGround } from '../../components/background';
import { Seo } from '../../components/seo';
import * as CommonStyles from '../../styles/global/_common.module.scss';
import Twitter from '/static/twitter.svg';
import Twitter_m from '/static/twitter_muted.svg';
import Facebook from '/static/facebook.svg';
import Facebook_m from '/static/facebook_muted.svg';
import Instagram from '/static/insta.svg';
import Instagram_m from '/static/insta_muted.svg';
import Youtube from '/static/youtube.svg';
import Youtube_m from '/static/youtube_muted.svg';
import classNames from 'classnames';
import {useIntl} from "gatsby-plugin-react-intl"

const ArtistPage = ({ data, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  const images = data.allMicrocmsIllust.edges.map(({ node }) => {
    return {
      image: `${node.image.url}?fm=webp`,
      title: node.title,
      artist: node.artist
    }
  });
  const [galleryIndex, setGalleryIndex] = React.useState(0);
  const { LightBox, open } = useLightBox('gallery', setGalleryIndex);

  const postDescription = data.microcmsArtist.description ? data.microcmsArtist.description.substring(0, 210) : '';

  return (
    <Layout location={location}>
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
          {
            title: t('menu.artist'),
            link: '/artist',
          },
        ]}
        currentPage={data.microcmsArtist.name}
      />
      <Seo title={`${data.microcmsArtist.name} - ${t('menu.artist')}`} description={postDescription} image={data.microcmsArtist.image_url || (data.microcmsArtist.image && data.microcmsArtist.image.url) + 'w=1200&fm=webp&fit=crop&q=80'} />
      <BackGround>
        <section className={classNames(Styles.artistId, CommonStyles.wrapper)}>
          <div>
            <div className={CommonStyles.container}>
              <h1>{data.microcmsArtist.name}</h1>
              {(data.microcmsArtist.image_url || data.microcmsArtist.image) && (
                <img src={(data.microcmsArtist.image_url || data.microcmsArtist.image.url) + '?w=200'} alt="" className={Styles.artistId__img} />
              )}
              <div>
                <div className={Styles.artistId__skill_set}>
                  <div className={Styles.artistId__skill_score}>
                    <p className={Styles.artistId__skill_title}>Idea</p>
                    <p className={Styles.artistId__skill_num}>{data.microcmsArtist.idea}</p>
                  </div>
                  <div className={Styles.artistId__skill_graph}>
                    <div className={Styles.artistId__skill_green} style={{ width: `calc(${data.microcmsArtist.idea ?? 0} / 5 * 100%)` }}></div>
                  </div>
                </div>
                <div className={Styles.artistId__skill_set}>
                  <div className={Styles.artistId__skill_score}>
                    <p className={Styles.artistId__skill_title}>Speed</p>
                    <p className={Styles.artistId__skill_num}>{data.microcmsArtist.speed}</p>
                  </div>
                  <div className={Styles.artistId__skill_graph}>
                    <div className={Styles.artistId__skill_pink} style={{ width: `calc(${data.microcmsArtist.speed ?? 0} / 5 * 100%)` }}></div>
                  </div>
                </div>
                <div className={Styles.artistId__skill_set}>
                  <div className={Styles.artistId__skill_score}>
                    <p className={Styles.artistId__skill_title}>Technique</p>
                    <p className={Styles.artistId__skill_num}>{data.microcmsArtist.technique}</p>
                  </div>
                  <dv className={Styles.artistId__skill_graph}>
                    <div className={Styles.artistId__skill_yellow} style={{ width: `calc(${data.microcmsArtist.technique ?? 0} / 5 * 100%)` }}></div>
                  </dv>
                </div>
                <div className={Styles.artistId__skill_set}>
                  <div className={Styles.artistId__skill_score}>
                    <p className={Styles.artistId__skill_title}>VST</p>
                    <p className={Styles.artistId__skill_num}>{data.microcmsArtist.vst}</p>
                  </div>
                  <div className={Styles.artistId__skill_graph}>
                    <div className={Styles.artistId__skill_purple} style={{ width: `calc(${data.microcmsArtist.vst ?? 0} / 5 * 100%)` }}></div>
                  </div>
                </div>
              </div>
              <p className={Styles.artistId__location}>
                <img src={Pin} className="pin" alt="pin" />
                {data.microcmsArtist.region}
              </p>
              <p className={Styles.artistId__url}>
                <a href={data.microcmsArtist.homepage} target="_blank">{data.microcmsArtist.homepage}</a>
              </p>
              <div
                dangerouslySetInnerHTML={{
                  __html: data.microcmsArtist.description,
                }}
                className={Styles.artistId__text}
              />
              <div className={Styles.artistId__sns}>
                {data.microcmsArtist.twitter ? (
                  <a href={data.microcmsArtist.twitter} target="_blank">
                    <img src={Twitter} className="twitter" alt="twitter" />
                  </a>
                ) : (
                  <span>
                    <img src={Twitter_m} className="twitter" alt="twitter" />
                  </span>
                )}
                {data.microcmsArtist.facebook ? (
                  <a href={data.microcmsArtist.facebook} target="_blank">
                    <img src={Facebook} className="facebook" alt="facebook" />
                  </a>
                ) : (
                  <span>
                    <img src={Facebook_m} className="facebook" alt="facebook" />
                  </span>
                )}
                {data.microcmsArtist.instagram ? (
                  <a href={data.microcmsArtist.instagram} target="_blank">
                    <img src={Instagram} className="instagram" alt="instagram" />
                  </a>
                ) : (
                  <span>
                    <img src={Instagram_m} className="instagram" alt="instagram" />
                  </span>
                )}
                {data.microcmsArtist.youtube ? (
                  <a href={data.microcmsArtist.youtube} target="_blank">
                    <img src={Youtube} className="youtube" alt="youtube" />
                  </a>
                ) : (
                  <span>
                    <img src={Youtube_m} className="youtube" alt="youtube" />
                  </span>
                )}
              </div>

              <h2>Works</h2>
              {data.allMicrocmsIllust.edges.length ? (
                <div className={Styles.my_masonry_grid}>
                  {data.allMicrocmsIllust.edges.map(({ node }, index) => (
                    <p key={node.illustId}>
                      <button
                        type="button"
                        onClick={() => {
                          setGalleryIndex(index);
                          open();
                        }}
                      >
                        <img src={node.image.url + '?fit=crop&w=300&h=300&fm=webp'} alt="" />
                      </button>
                    </p>
                  ))}
                </div>
              ) : (
                <p>{t('artist.nodata')}</p>
              )}

              <LightBox images={images} currentIndex={galleryIndex} onMovePrev={() => setGalleryIndex((galleryIndex + images.length - 1) % images.length)} onMoveNext={() => setGalleryIndex((galleryIndex + 1) % images.length)} navigation={images.length > 1} caption={false} />
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export const query = graphql`
  query ($artistId: String) {
    microcmsArtist(artistId: { eq: $artistId }) {
      name
      image_url
      image {
        url
      }
      description
      region
      homepage
      twitter
      facebook
      instagram
      youtube
      idea
      speed
      technique
      vst
    }

    allMicrocmsIllust(filter: { artist: { id: { eq: $artistId } } }) {
      edges {
        node {
          illustId
          image {
            url
          }
          artist {
            artistId
          }
        }
      }
    }
  }
`;

export default ArtistPage;
