// extracted by mini-css-extract-plugin
export var artistId__img = "_artistId-module--artistId__img--lPhk1";
export var artistId__skill_set = "_artistId-module--artistId__skill_set--j0-wr";
export var artistId__skill_score = "_artistId-module--artistId__skill_score--x8iuW";
export var artistId__skill_title = "_artistId-module--artistId__skill_title--SZJXT";
export var artistId__skill_num = "_artistId-module--artistId__skill_num--RFi6H";
export var artistId__skill_graph = "_artistId-module--artistId__skill_graph---sDdO";
export var artistId__skill_green = "_artistId-module--artistId__skill_green--9---J";
export var artistId__skill_pink = "_artistId-module--artistId__skill_pink--YP-kD";
export var artistId__skill_yellow = "_artistId-module--artistId__skill_yellow--GjxFZ";
export var artistId__skill_purple = "_artistId-module--artistId__skill_purple--QrxP5";
export var artistId__location = "_artistId-module--artistId__location--JTEgB";
export var artistId__url = "_artistId-module--artistId__url--LhKEx";
export var artistId__text = "_artistId-module--artistId__text--q9HTF";
export var artistId = "_artistId-module--artistId--8rSMX";
export var artistId__sns = "_artistId-module--artistId__sns--MnlYz";
export var my_masonry_grid = "_artistId-module--my_masonry_grid--N2ckq";