import React from 'react';
import { createPortal } from 'react-dom';
import './micromodal.scss';

export const Modal = ({ children, id }) => {
  return typeof document !== 'undefined'
    ? createPortal(
        <div id={id} aria-hidden="true" className="micromodal-wrap">
          <div className="micromodal-overlay" tabIndex={-1} data-micromodal-close>
            <div role="dialog" className="micromodal-dialog" aria-modal="true">
              {children}
            </div>
          </div>
        </div>,
        document.body
      )
    : null;
};
