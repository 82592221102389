import React from 'react';
import { useHook } from './modal.hook';
import { Modal as ModalComponent } from './modal.view';
import { useCallback } from 'react';

export const useMicromodal = (id, setIndex) => {
  const { open, close } = useHook(id, setIndex);

  const Modal = useCallback(
    ({ children }) => {
      return <ModalComponent id={id}>{children}</ModalComponent>;
    },
    [id]
  );

  return {
    Modal,
    open,
    close,
  };
};
