import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import * as Styles from '../../styles/components/_lightbox.module.scss';
import Prev from '/static/arrow_prev_icon.svg';
import Next from '/static/arrow_next_icon.svg';
import { contentPathHelper } from '../../utils/contentPathHelper';

const ModalView = ({ images, currentIndex, onMovePrev, onMoveNext, navigation, caption, close }) => {
  const timeOut = 300;
  const [animationFlag, setAnimationFlag] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setAnimationFlag(true);
    }, timeOut);
  }, [currentIndex]);

  return (
    <>
      <div className={Styles.container}>
        {/* navigate-prev */}
        {navigation && (
          <button
            className={Styles.navigate__prev}
            onClick={() => {
              onMovePrev();
              setAnimationFlag(false);
            }}
          >
            <img src={Prev} alt="" />
          </button>
        )}

        {/* image-content */}
        <div className={Styles.image_content}>
          {
            images.map((item, i) => {
              if (currentIndex === i) {
                return (
                  <>
                    <div className={Styles.image_area}>
                      {item.image && (
                        <img
                          src={item.image}
                          alt={item.title}
                          className={animationFlag ? Styles.image_content__entered : Styles.image_content__entering}
                        />
                      )}
                    </div>
                    {caption && (
                      <div className={Styles.image_info}>
                        <span className={classNames(Styles.art_title, animationFlag ? Styles.image_content__entered : Styles.image_content__entering)} title={item.title}>
                          {item.title}
                        </span>
                        {item.artist &&
                          <Link to={`/artist/${contentPathHelper(item.artist.id)}/`} onClick={() => close()} className={animationFlag ? Styles.image_content__entered : Styles.image_content__entering}>
                            {item.artist && (
                              <>
                                <img src={(item.artist.image_url || item.artist.image.url) + '?fm=webp'} alt="" />
                                {item.artist.name}
                              </>
                            )
                            }
                          </Link>
                        }
                      </div>
                    )}
                  </>
                )
              }
              return null
            })
          }
        </div>

        {/* navigate-next */}
        {navigation && (
          <button
            className={Styles.navigate__next}
            onClick={() => {
              onMoveNext();
              setAnimationFlag(false);
            }}
          >
            <img src={Next} alt="" />
          </button>
        )}
      </div>
    </>
  );
};

export default ModalView;
