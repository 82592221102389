import client from 'micromodal';
import { useCallback } from 'react';

export const useHook = (id, setIndex) => {
  const open = useCallback(() => {
    client.show(id, {
      disableScroll: true,
      awaitOpenAnimation: true,
      awaitCloseAnimation: true,
      onClose: () => {
        // set galleryIndex
        setIndex && setIndex(null);
      },
    });
  }, [id]);

  const close = useCallback(() => {
    client.close(id);
  }, [id]);

  return { open, close };
};
