import React, { useCallback } from 'react';
import { useMicromodal } from '../../lib/micromodal';
import ModalView from './view';

export const useLightBox = ({ id = 'lightbox' }, setIndex) => {
  const { Modal: OverView, open, close } = useMicromodal(id, setIndex);

  const LightBox = useCallback(
    ({
      images = [],
      currentIndex = 0,
      onMovePrev = () => {},
      onMoveNext = () => {},
      navigation = true,
      caption = true,
      close = () => {
        close();
      },
      open = () => {
        open();
      },
    }) => {
      return (
        <OverView>
          <ModalView images={images} currentIndex={currentIndex} onMovePrev={onMovePrev} onMoveNext={onMoveNext} navigation={navigation} caption={caption} />
        </OverView>
      );
    },
    [OverView]
  );

  return { LightBox, open, close };
};
